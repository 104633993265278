import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Box,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  useLocation,
  useParams,
  Link as RouterLink,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { ExtractParams } from '@/types/params';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import { useGetOnePropertyLocation } from '@/api/endpoints/propertyLocation/useGetOnePropertyLocation';
import { Users01Icon } from '@/assets/icons/Users01Icon';
import { PMPRoutes } from '@/config/routes';
import { usePropertyManager } from '@/context/PropertyManagerProvider';
import { formatPhoneNumber } from '@/utils/phone';
import { getResolvedRoutePath } from '@/utils/router';

import InviteTenantModal from '@/components/tenant/InviteTenantModal';

import PropertyLocationViewBuildingListSubpage from './propertyLocation-view/PropertyLocationViewBuildingListSubpage';
import PropertyLocationViewKnowledgeSubpage from './propertyLocation-view/PropertyLocationViewKnowledgeSubpage';
import PropertyLocationViewPropertyManagerListPage from './propertyLocation-view/PropertyLocationViewPropertyManagerListPage';
import PropertyLocationViewQuestionAnswerListSubpage from './propertyLocation-view/PropertyLocationViewQuestionAnswerListSubpage';
import PropertyLocationViewRulesSubpage from './propertyLocation-view/PropertyLocationViewRulesSubpage';
import UnitViewRentSubpage from './unit-view/UnitViewRentSubpage';
import UnitViewTenantListSubpage from './unit-view/UnitViewTenantListSubpage';

const PropertyLocationViewPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const { id } = useParams<ExtractParams<PMPRoutes.propertyLocationView>>();
  const { isSingleFamilyLocation, setSelectedPropertyLocationId } = usePropertyManager();
  const [isInviteTenantModalOpen, setIsInviteTenantModalOpen] = useState(false);

  const getOnePropertyLocationQuery = useGetOnePropertyLocation(Number(id));

  // Update the selected property location when the page loads
  useEffect(() => {
    if (id) {
      setSelectedPropertyLocationId(Number(id));
    }
  }, [id, setSelectedPropertyLocationId]);

  // Check for openInviteTenant query parameter
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('openInviteTenant') === 'true') {
      setIsInviteTenantModalOpen(true);
    }
  }, [location.search]);

  // Generate navigation items based on isSingleFamilyLocation
  const navigationItems = [
    ...(isSingleFamilyLocation
      ? [
        {
          label: 'Tenants',
          path: getResolvedRoutePath(PMPRoutes.propertyLocationViewTenantList, {
            locationId: id,
          }),
        },
        {
          label: 'Rent',
          path: getResolvedRoutePath(PMPRoutes.propertyLocationViewRent, {
            locationId: id,
          }),
        },
      ]
      : [
        {
          label: 'Buildings',
          path: getResolvedRoutePath(PMPRoutes.propertyLocationViewBuildingList, { id }),
        },
        {
          label: 'Questions & Answers',
          path: getResolvedRoutePath(PMPRoutes.propertyLocationViewQuestionAnswerList, { id }),
        },
      ]),
    {
      label: 'Property Partners',
      path: getResolvedRoutePath(PMPRoutes.propertyLocationManagerList, { id }),
    },
    ...(isSingleFamilyLocation
      ? []
      : [
        {
          label: 'Property General Rules',
          path: getResolvedRoutePath(PMPRoutes.propertyLocationViewRuleList, { id }),
        },
        {
          label: 'Knowledge',
          path: getResolvedRoutePath(PMPRoutes.propertyLocationViewKnowledge, { id }),
        },
      ]),
  ];

  return (
    <Stack
      direction="column"
      spacing={isMobile ? 1 : 2}
      sx={{ flex: '1 1 100%', height: '100%', display: 'flex' }}
    >
      <DashboardHeader
        breadcrumbs={[{ label: 'Locations', to: PMPRoutes.propertyLocationList }]}
        title={getOnePropertyLocationQuery.data?.address_text}
      >
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 1 : 2}
          alignItems={isMobile ? 'flex-start' : 'center'}
          width={isMobile ? '100%' : 'auto'}
        >
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<Users01Icon />}
            onClick={() => setIsInviteTenantModalOpen(true)}
            fullWidth={isMobile}
            size={isMobile ? 'small' : 'medium'}
          >
            Invite Tenants
          </Button>
          <Stack spacing={0.25} alignItems={isMobile ? 'flex-start' : 'flex-end'}>
            {!!getOnePropertyLocationQuery.data?.email && (
              <Typography variant={isMobile ? 'body2' : 'body3'}>
                {getOnePropertyLocationQuery.data.email.value}
              </Typography>
            )}

            {!!getOnePropertyLocationQuery.data?.phone && (
              <Typography variant={isMobile ? 'body2' : 'body3'}>
                {formatPhoneNumber(getOnePropertyLocationQuery.data.phone.value)}
              </Typography>
            )}
          </Stack>
        </Stack>
      </DashboardHeader>

      <ToggleButtonGroup value={location.pathname} role="navigation" exclusive>
        {navigationItems.map(item => (
          <ToggleButton key={item.path} value={item.path} component={RouterLink} to={item.path}>
            {item.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: '400px',
          height: 'calc(100vh - 200px)',
          width: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Switch>
          {!isSingleFamilyLocation && (
            <Route
              exact
              path={PMPRoutes.propertyLocationViewBuildingList}
              component={PropertyLocationViewBuildingListSubpage}
            />
          )}
          {isSingleFamilyLocation && (
            <Route
              exact
              path={PMPRoutes.propertyLocationViewTenantList}
              component={UnitViewTenantListSubpage}
            />
          )}
          {isSingleFamilyLocation && (
            <Route
              exact
              path={PMPRoutes.propertyLocationViewRent}
              component={UnitViewRentSubpage}
            />
          )}
          {!isSingleFamilyLocation && (
            <Route
              exact
              path={PMPRoutes.propertyLocationViewQuestionAnswerList}
              component={PropertyLocationViewQuestionAnswerListSubpage}
            />
          )}
          <Route
            exact
            path={PMPRoutes.propertyLocationManagerList}
            component={PropertyLocationViewPropertyManagerListPage}
          />
          {!isSingleFamilyLocation && (
            <Route
              exact
              path={PMPRoutes.propertyLocationViewRuleList}
              component={PropertyLocationViewRulesSubpage}
            />
          )}
          {!isSingleFamilyLocation && (
            <Route
              exact
              path={PMPRoutes.propertyLocationViewKnowledge}
              component={PropertyLocationViewKnowledgeSubpage}
            />
          )}

          <Route>
            {isSingleFamilyLocation ? (
              <Redirect
                to={getResolvedRoutePath(PMPRoutes.propertyLocationViewTenantList, {
                  locationId: id,
                })}
              />
            ) : (
              <Redirect
                to={getResolvedRoutePath(PMPRoutes.propertyLocationViewBuildingList, { id })}
              />
            )}
          </Route>
        </Switch>
      </Box>

      {isInviteTenantModalOpen && getOnePropertyLocationQuery.data?.id && (
        <InviteTenantModal
          buildingId={0}
          locationId={getOnePropertyLocationQuery.data.id}
          onClose={() => {
            setIsInviteTenantModalOpen(false);
            // Clear the URL parameter
            const newUrl = new URL(window.location.href);
            newUrl.searchParams.delete('openInviteTenant');
            window.history.replaceState({}, '', newUrl.toString());
          }}
          onTenantsInvited={() => {
            setIsInviteTenantModalOpen(false);
          }}
        />
      )}
    </Stack>
  );
};

export default PropertyLocationViewPage;
