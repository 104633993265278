import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';

import { GenerateRentLinkDto } from '@/types/dto/rent-unit.dto';

import { useGenerateRentLink } from '@/api/endpoints/rent/useGenerateRentLink';

import FormTextField from '@/components/form/FormTextField';

interface GenerateLinkFormValues {
  tenantEmail: string;
  tenantName: string;
}

interface GenerateSubscriptionLinkSectionProps {
  unitId: number;
}

const GenerateSubscriptionLinkSection: React.FC<GenerateSubscriptionLinkSectionProps> = ({
  unitId,
}) => {
  // Set up react-hook-form for generating link
  const generateLinkForm = useForm<GenerateLinkFormValues>({
    defaultValues: {
      tenantEmail: '',
      tenantName: '',
    },
    mode: 'onChange',
  });

  // Set up validation schema
  React.useEffect(() => {
    generateLinkForm.register('tenantEmail', {
      required: 'Email is required',
      pattern: {
        value: /^\S+@\S+\.\S+$/,
        message: 'Invalid email format',
      },
    });

    generateLinkForm.register('tenantName', {
      required: 'Full name is required',
    });
  }, [generateLinkForm]);

  // State for displaying generated link
  const [generatedLink, setGeneratedLink] = React.useState<string | null>(null);

  // Mutation for generating link
  const generateRentLinkMutation = useGenerateRentLink(unitId, {
    onSuccess: data => {
      setGeneratedLink(data.checkoutUrl);
    },
    // onError handled within the hook via enqueueSnackbar
  });

  // Handler for submitting link generation form
  const handleGenerateLink = generateLinkForm.handleSubmit(data => {
    const linkData: GenerateRentLinkDto = {
      tenantEmail: data.tenantEmail,
      tenantName: data.tenantName,
    };
    generateRentLinkMutation.mutate(linkData);
  });

  return (
    <Card>
      <CardHeader title="Generate Tenant Subscription Link" />
      <CardContent>
        <Box component="form" onSubmit={handleGenerateLink} noValidate sx={{ mt: 1 }}>
          <Stack spacing={2}>
            <FormTextField
              name="tenantEmail"
              label="Tenant Email Address"
              control={generateLinkForm.control}
              required
              helperText={generateLinkForm.formState.errors.tenantEmail?.message}
              error={!!generateLinkForm.formState.errors.tenantEmail}
              textFieldProps={{
                fullWidth: true,
                autoComplete: 'email',
              }}
            />
            <FormTextField
              name="tenantName"
              label="Tenant Full Name"
              control={generateLinkForm.control}
              required
              helperText={generateLinkForm.formState.errors.tenantName?.message}
              error={!!generateLinkForm.formState.errors.tenantName}
              textFieldProps={{
                fullWidth: true,
                autoComplete: 'name',
              }}
            />
          </Stack>
          <Button
            type="submit"
            variant="contained"
            disabled={
              generateRentLinkMutation.isPending ||
              !!generateLinkForm.formState.errors.tenantEmail ||
              !!generateLinkForm.formState.errors.tenantName
            }
            sx={{ mt: 3, mb: 2 }}
          >
            {generateRentLinkMutation.isPending ? <CircularProgress size={24} /> : 'Generate Link'}
          </Button>
        </Box>
        {/* Display generated link or error */}
        <>
          {generateRentLinkMutation.isSuccess && generatedLink && (
            <Box
              sx={{
                mt: 2,
                p: 2,
                border: '1px solid grey',
                borderRadius: 1,
                wordBreak: 'break-all',
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                Generated Link:
              </Typography>
              <Typography
                component="a"
                href={generatedLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {generatedLink}
              </Typography>
            </Box>
          )}
          {generateRentLinkMutation.isError && (
            <Typography color="error" sx={{ mt: 2 }}>
              Failed to generate link. Please try again.
            </Typography>
          )}
        </>
      </CardContent>
    </Card>
  );
};

export default GenerateSubscriptionLinkSection;
