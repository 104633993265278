import { CircularProgress, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ExtractParams } from '@/types/params';

import { useGetAllBuildings } from '@/api/endpoints/building/useGetAllBuildings';
// Import the new hook
import { useGetAllUnits } from '@/api/endpoints/unit/useGetAllUnits';
import { PMPRoutes } from '@/config/routes';
import { usePropertyManager } from '@/context/PropertyManagerProvider';

// Import the new sub-components
import GenerateSubscriptionLinkSection from './GenerateSubscriptionLinkSection';
import RentDetailsFormSection from './RentDetailsFormSection';

// Unused interfaces removed during refactoring

const UnitViewRentSubpage: React.FC = () => {
  const { id: unitIdString } = useParams<ExtractParams<PMPRoutes.unitView>>();
  const { locationId: locationIdString } =
    useParams<ExtractParams<PMPRoutes.propertyLocationViewRent>>();
  const { isSingleFamilyLocation } = usePropertyManager();

  // For direct unit access
  const directUnitId = unitIdString ? Number(unitIdString) : undefined;
  const locationId = locationIdString ? Number(locationIdString) : undefined;

  // Initialize state to track the unit ID
  const [unitId, setUnitId] = React.useState<number | undefined>(directUnitId);

  // For single family homes, get the first unit ID if not provided
  const getAllBuildingsQuery = useGetAllBuildings(
    {
      location_id: locationId,
    },
    !!locationId && isSingleFamilyLocation && !directUnitId
  );

  const getAllUnitsQuery = useGetAllUnits(
    {
      buildingId: getAllBuildingsQuery.data?.[0]?.id,
    },
    !!getAllBuildingsQuery.data?.[0]?.id && isSingleFamilyLocation && !directUnitId
  );

  // Set the unit ID when we get it for single family homes
  useEffect(() => {
    if (isSingleFamilyLocation && !unitId && getAllUnitsQuery.data?.[0]?.id) {
      setUnitId(getAllUnitsQuery.data[0].id);
    }
  }, [isSingleFamilyLocation, unitId, getAllUnitsQuery.data]);

  // Loading state for determining unitId in single-family case
  const isLoadingUnitId = isSingleFamilyLocation && !unitId;

  if (isLoadingUnitId) {
    return (
      <Stack alignItems="center" justifyContent="center" height="100%">
        <CircularProgress />
      </Stack>
    );
  }

  // Render the sub-components once unitId is available
  return (
    <Stack spacing={3}>
      {unitId ? (
        <>
          <RentDetailsFormSection unitId={unitId} />
          <GenerateSubscriptionLinkSection unitId={unitId} />
        </>
      ) : (
        // Should ideally not happen if isLoadingUnitId is handled, but as a fallback
        <Typography>Loading unit information...</Typography>
      )}
    </Stack>
  );
};

export default UnitViewRentSubpage;
